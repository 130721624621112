.back {
  position: absolute;
  top: 65px;
  right: 25px;
  background: #FFFFFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 60px;
  width: 160px;
  border-radius: 30px 0 0 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #E6215A;
  z-index: 2;
}

.tracker {
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  min-height: 100vh;

  &__logo {
    position: absolute;
    top: 35px;
    left: 90px;
  }

  &__back {
    top: 15px;
    right: 0;
  }

  &__info {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding-left: 90px;
    background: #F7F9FB;

    img {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &-title {
      max-width: 374px;
      font-family: 'Rubik-ExtraBold', sans-serif;
      font-weight: 800;
      font-size: 66px;
      line-height: 64px;
      color: #3C3C3B;
      transform: translateY(-150px);
    }
  }

  &__body {
    width: 100%;
    display: grid;
    grid-template-columns: 6fr 1fr 6fr;
  }

  &__left {
    background-color: #009757;
    padding-left: 90px;
    position: relative;
  }

  &__center {
    background-color: #EF7D00;
    border-right: 10px solid #FFFFFF;
    border-left: 10px solid #FFFFFF;
  }

  &__right {
    background-color: #E6215A;
    padding-left: 90px;
    position: relative;
  }

  &__title {
    position: absolute;
    top: 243px;
    left: 90px;

    &-text {
      font-family: 'Rubik-Bold', sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      margin: 70px 0;
      color: #FFFFFF;
      width: 400px;
    }

    &-top-text {
      font-family: 'Rubik-Regular', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 80px;
      color: #FFFFFF;
      width: 380px;
      position: absolute;
      top: 100px;
    }
  }

  &__btn {
    background: #FFFFFF;
    border-radius: 76px;
    width: 171px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25.0633px;
    line-height: 52px;
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    color: #E6215A;
    position: absolute;
    bottom: 60px;
    right: 90px;
    cursor: pointer;
  }
}

.answer-item {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  color: #FFFFFF;
  margin-bottom: 15px;
}
