.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 30px 120px 90px 120px;
}

.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 140px;
  height: 100%;
}

.left-block {
  width: 1060px;
}

.question-number {
  font-size: 24px;
  line-height: 130%;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  color: #77797A;
  margin-bottom: 30px;
}

.title {
  font-size: 30px;
  line-height: 130%;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.text {
  &-up {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #00A555;
    margin-bottom: 15px;
  }

  &-down {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    font-style: italic;
    color: #77797A;
    margin-bottom: 40px;
  }
}

.right-block {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 472px;
}

.image-container {
  margin-bottom: 120px;
  width: 100%;
  height: 383px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background: #00A555;
  border-radius: 10px;
  width: 280px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 130%;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background-color: #00A555;
    color: #FFFFFF;
  }
}


.answer {
  display: none;

  &__label {
    width: 100%;
    height: 66px;
    margin-bottom: 18px;
    background: #FFFFFF;
    border: 2px solid #00A555;
    border-radius: 10px;
    padding-left: 33px;
    font-size: 20px;
    line-height: 130%;
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;

    &--active {
      background-color: #00A555;
      color: #FFFFFF;
      font-family: 'Rubik-Bold', sans-serif;
      font-weight: 700;
    }
  }
}

