.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 40px;
  line-height: 130%;
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 65px;
}

.container {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}

.image-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  width: 206px;
}

.image-inner {
  width: 206px;
  height: 203px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-content {
  width: 700px;
}

.field {
  font-size: 24px;
  line-height: 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  color: #77797A;
  margin-top: 45px;
  text-align: center;
}

.item {
  padding: 0 52px;
  display: flex;
  justify-content: center;
  gap: 50px;
  //max-width: 962px;

  &:first-child {
    border-right: 2px solid #EF7D00;
  }

  &-title {
    font-size: 30px;
    line-height: 36px;
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    padding-bottom: 30px;
  }

  &-text {
    font-size: 22px;
    line-height: 32px;
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
  }

  &-description {
    margin-bottom: 50px;
  }

  &-point {
    margin-bottom: 15px;
    color: #77797A;

    span {
      color: #000;
      font-family: 'Rubik-Bold', sans-serif;
      font-weight: 700;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-top: 50px;
  padding-bottom: 30px;
}

.btn {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  color: #EF7D00;
  text-transform: uppercase;
  cursor: pointer;
}

.return-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 30px;
  background-color: #fff;
  border: none;
  border-radius: 100px 0 0 100px;
  position: absolute;
  top: 87px;
  right: 0;

  span {
    font-family: "Rubik-ExtraBold", sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 14px;
    color: #BC213E;
    margin-left: 15px;
  }
}