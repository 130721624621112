.keyboard {
  width: 1150px;
  padding-bottom: 40px;

  input {
    width: 100%;
    height: 60px;
    padding: 20px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
  }
}

.simple-keyboard {
  max-width: 850px;
}
