.wrapper {
  height: 100%;
}

.content {
  padding: 50px 120px 75px;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-block {
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.top-text {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  margin-bottom: 40px;
}

.title {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 30px;
  color: #00A555;
  margin-bottom: 50px;
}

.description {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 20px;
}

.list {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  flex: 1 1 auto;

  &-title {
    margin-bottom: 15px;
  }

  &-container {
    list-style: disc;

    li {
      margin-left: 20px;
    }
  }
}

.input {
  &-label {
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #00A555;
    display: block;
    margin-bottom: 15px;
  }

  &-field {
    //outline: none;
    background: #FFFFFF;
    border: 2px solid #00A555;
    border-radius: 10px;
    width: 830px;
    height: 66px;
    padding-left: 33px;
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    align-items: center;

  //  &::placeholder {
  //    font-family: 'Rubik-Regular', sans-serif;
  //    font-weight: 400;
  //    font-size: 20px;
  //    line-height: 130%;
  //    color: #939598;
  //  }
  }
}

.input-span {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #939598;
}


.right-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  &-wrapper {
    position: relative;
    width: 491px;
    padding: 40px 20px 0;
    transform: translateY(-20px);
  }

  &-container {
    width: 218px;
    height: 560px;
    position: absolute;
    top: 62px;
    left: 46px;
    padding: 22px 18px;
  }

  &-title {
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 23px;
  }

  &-description {
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 15px;
  }

  &-list {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;

    &-title {
      margin-bottom: 10px;
    }

    &-container {
      list-style: arabic;

      li {
        margin: 0 0 10px 10px;
      }
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 16px;
  }

  &-btn {
    background: #00A555;
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 316px;
    height: 66px;
    font-size: 20px;
    line-height: 130%;
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    color: #FFFFFF;
  }
}

.first-img {
  width: 100%;
}

.second-img {
  width: 93%;
  position: absolute;
  top: 15px;
  right: 0;
}

.bg-img {
  position: absolute;
  width: 48%;
  top: 35px;
  left: 50px;
}

.motivation {
  width: 135px;
  height: 72px;
  outline: none;
  border-radius: 0;
  border: 1px solid #C4C4C4;
  position: absolute;
  top: 53px;
  right: 53px;
  padding: 5px;
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  color: #939598;
  display: flex;
  justify-content: center;
  align-items: center;
}
