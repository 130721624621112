.content {
  position: relative;

  img {
    width: 100%;
    height: 930px;
    object-fit: cover;
    object-position: center;
  }
}

.text-wrapper {
  max-width: 830px;
  position: absolute;
  top: 70px;
  left: 180px;
}

.text-result {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: #FFFFFF;
  margin-bottom: 50px;
}

.title {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 80px;
}

.bold-text {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.first-text {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.second-text {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 50px;
}

.btn {
  background: #E62259;
  border-radius: 10px;
  width: 280px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 130%;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  color: #FFFFFF;
}

.btns-block {
    display: flex;
    gap: 5rem;
    align-items: flex-start;

      img {
          width: 200px;
          height: 200px;
        }
}
