#canvas {
  position: relative;
  margin: 0 auto 1.5rem auto;
  display: block;
  width: 100%;
  height: auto;
  max-height: 600px;
  max-width: 600px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // Не будет колесо работать в мобиле, т.к. рассчет по canvas идет в пикселях.
  // width: 37.5rem;
  // max-width: 100%;
}

#answers .template {
  display: none;
}

//.hint {
//  position: absolute;
//  bottom: -2rem;
//  left: 50%;
//  transform: translateX(-50%);
//  z-index: 10;
//
//  width: 37.5rem;
//  max-width: 100%;
//
//  opacity: 0;
//  margin: auto;
//  min-height: 100px;
//  background-color: #EEE;
//  border-radius: 5px;
//  padding: 10px;
//  border: 1px solid #777;
//  pointer-events: none;
//}

.block-button-wrap {
  margin: 0.25rem;

  .btn {
    display: block;
  }
}

.lead {
  line-height: 1.15;
}

.bad-result-recomendations {
  img {
    width: 4rem;
  }
}


.decor-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
}

.back {
  position: absolute;
  top: 65px;
  right: 25px;
  background: #009757;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 60px;
  width: 160px;
  border-radius: 30px 0 0 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  z-index: 3;
  cursor: pointer;
}

.canvas-wheel {
  transform-origin: center;
  transform: scale(1.4) translateY(20px);
}

.tracker {
  display: flex;
  position: relative;
  background-color: #FFFFFF;

  &__logo {
    position: absolute;
    top: 35px;
    left: 90px;
  }

  &__back {
    top: 15px;
    right: 0;
    cursor: pointer;
  }

  &__info {
    width: calc(100% - 1300px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding-left: 90px;
    background: #F7F9FB;

    &-title {
      max-width: 374px;
      font-family: 'Rubik-ExtraBold', sans-serif;
      font-weight: 800;
      font-size: 66px;
      line-height: 64px;
      color: #3C3C3B;
    }

    &-text {
      font-family: 'Rubik-Regular', sans-serif;
      font-weight: 400;
      font-size: 25px;
      line-height: 130%;
      color: #3C3C3B;

      span {
        font-family: 'Rubik-Bold', sans-serif;
        font-weight: 700;
      }
    }
  }

  &__body {
    width: 1300px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 1080px;
    position: relative;
    margin: 0 auto;
  }

  &__item {
    width: 674px;
    height: 643px;
    position: relative;
    transform: translateY(-52px);
  }

  .tracker__tip {
    position: absolute;
    font-family: 'Rubik-ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 15px;
    line-height: 15px;

    &-text {
      width: 270px;
      border-radius: 20px;
      border: 3px solid;
      padding: 15px;
      font-weight: 700;
      font-size: 15.5715px;
    }

    &-icon {
      height: 75px;
      width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }

    &.track1 {
      color: #31B7BC;
      top: -130px;
      left: -90px;

      .tracker__tip-text {
        width: 295px;
        border-color: #D3FDFF;
      }

      .tracker__tip-icon {
        left: -85px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 75.67px;
        height: 57.81px;
        bottom: -57px;
        right: 65px;
        background: url("../../assets/images/Tracker/tip1-arrow.png") no-repeat center;
        background-size: contain;
      }
    }

    &.track2 {
      color: #004F9F;
      top: -135px;
      right: -65px;

      .tracker__tip-text {
        width: 295px;
        border-color: #C8E3FF;
      }

      .tracker__tip-icon {
        right: -85px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 81.16px;
        height: 42.95px;
        bottom: -42px;
        right: 144px;
        background: url("../../assets/images/Tracker/tip2-arrow.png") no-repeat center;
        background-size: contain;
      }
    }

    &.track3 {
      color: #E6215A;
      right: -254px;
      top: 77px;

      .tracker__tip-text {
        border-color: #FFBED0;
      }

      .tracker__tip-icon {
        right: 0;
        bottom: -85px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 92.73px;
        height: 164.36px;
        bottom: -164;
        right: 129px;
        background: url("../../assets/images/Tracker/tip3-arrow.png") no-repeat center;
        background-size: contain;
      }
    }

    &.track4 {
      color: #EF7D00;
      right: -260px;
      bottom: -15px;

      .tracker__tip-text {
        border-color: #FFCE98;
      }

      .tracker__tip-icon {
        right: 0;
        bottom: -85px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 126.16px;
        height: 14.1px;
        bottom: 20px;
        left: -126px;
        background: url("../../assets/images/Tracker/tip4-arrow.png") no-repeat center;
        background-size: contain;
      }
    }

    &.track5 {
      color: #009757;
      left: -269px;
      bottom: -12px;

      .tracker__tip-text {
        border-color: #A3FFD7;
      }

      .tracker__tip-icon {
        left: 0;
        bottom: -85px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 127.89px;
        height: 14.1px;
        bottom: 20px;
        right: -127px;
        background: url("../../assets/images/Tracker/tip5-arrow.png") no-repeat center;
        background-size: contain;
      }
    }

    &.track6 {
      color: #989B9E;
      left: -269px;
      bottom: 460px;

      .tracker__tip-text {
        border-color: #EEF0F1;
      }

      .tracker__tip-icon {
        left: 0;
        bottom: -85px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 86.31px;
        height: 162.44px;
        bottom: -162px;
        right: 40px;
        background: url("../../assets/images/Tracker/tip6-arrow.png") no-repeat center;
        background-size: contain;
      }
    }
  }
}

.result-window {
  width: 970px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 32px;
  transform: translateX(-50%) translateY(-15px);

  &__bold-text {
    font-family: 'Rubik-ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 23px;
    line-height: 40px;
    text-align: center;
  }

  &__text {
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    font-size: 23px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__btn {
    width: 125px;
    height: 65px;
    background: #009757;
    border-radius: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    line-height: 52px;
    color: #FFFFFF;
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
  }
}