.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  position: relative;
  flex: 1 1 auto;
}

.left-block {
  position: absolute;
  top: 32px;
  left: 142px;
  max-width: 1190px;
}

.number-string {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #77797A;
  margin-bottom: 30px;
}

.question {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  margin-bottom: 40px;
  height: 156px;
}

.answers-block {
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 43px;
  right: 67px;
}

.pushkin-img {
  margin-bottom: 35px;
  width: 521px;
  height: 609px;
}

.btn {
  width: 280px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E62259;
  border-radius: 10px;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF;
}