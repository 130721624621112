.window {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background: rgba(0, 0, 0, 0.4);

    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        position: relative;
        background: #fff;
        padding: 20px;
    }
}

.close-btn {
    display: flex;
    justify-content: flex-end;
}

.bottom-strip {
    height: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
