.email-window {
  width: 700px;

  &-container {
    width: 462px;
    margin: 0 auto;
  }
}

.input-email {
  margin-bottom: 16px;
  margin-top: 70px;
  position: relative;

  input {
    outline: none;
    border: 1.7px solid #009757;
    width: 100%;
    height: 58px;
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    padding-left: 21px;
  }
}

.email-placeholder {
  display: flex;
  align-items: center;
  gap: 22px;
  position: absolute;
  top: 16px;
  left: 21px;
  pointer-events: none;
}

.text {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 15px;
}

.checkbox {
  margin-bottom: 20px;
  position: relative;
  height: 40px;

  input {
    display: none;
  }

  label {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    position: absolute;
    top: 0;
    left: 55px;

    &:before {
      content: '';
      display: block;
      width: 21px;
      height: 21px;
      border: 2px solid #009757;
      position: absolute;
      top: 0;
      left: -55px;
    }
  }
}

.point {
  width: 17px;
  height: 17px;
  background-color: #009757;
  position: absolute;
  top: 4px;
  left: 4px;
}

.link {
  text-decoration: underline;
  color: #005DAC;
}

.btn {
  background: #009757;
  border-radius: 67px;
  border: none;
  height: 57px;
  width: 462px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  line-height: 46px;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 80px;

  &:disabled {
    opacity: 0.6;
  }
}

.gratitude {
  text-align: center;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  font-family: 'Rubik-Regular', sans-serif;
  color: #00A555;
  width: 800px;
  padding: 30px 0 50px;
}