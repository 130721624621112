.content {
  padding-top: 110px;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr;
  column-gap: 30px;
  position: relative;
}

.left {

}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-big {
    background: #EF7D00;
    width: 535px;
    height: 535px;
  }

  &-middle {
    background: #FF9A29;
    width: 493px;
    height: 493px;
  }

  &-small {
    background: #FFBE76;
    width: 451px;
    height: 451px;
    flex-direction: column;
    gap: 40px;
  }

  &-text {
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }
}

.right {

}



