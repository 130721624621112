.tracker {
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  min-height: 100vh;

  &__logo {
    position: absolute;
    top: 35px;
    left: 90px;
  }

  &__back {
    top: 15px;
    right: 0;
  }

  &__info {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding-left: 90px;
    padding-right: 60px;
    background: #F7F9FB;

    img {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &-title {
      max-width: 374px;
      font-family: 'Rubik-ExtraBold', sans-serif;
      font-weight: 800;
      font-size: 66px;
      line-height: 64px;
      color: #3C3C3B;
      transform: translateY(-150px);
    }
  }
}

.info {
  display: flex;
  position: relative;

  &__left {
    width: 30%;
    padding: 90px 20px 50px 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    font-weight: 800;
    font-size: 66px;
    line-height: 64px;
    color: #3C3C3B;
  }

  &__preview {
    transform: translate(-30px);
  }



  &__right {
    padding: 60px 90px 100px 100px;
    position: relative;

    &-title {
      width: 773px;
      font-family: 'Rubik-ExtraBold', sans-serif;
      font-weight: 800;
      font-size: 23px;
      line-height: 31px;
      margin-bottom: 30px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__list {
    width: 48%;
  }

  &__title {
    padding-left: 45px;
  }

  &__title {
    position: relative;

    &-num {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 800;
      font-size: 29px;
      line-height: 31px;
      color: #fff;
      -webkit-text-stroke: 3px #009757;
    }

    span {
      font-family: 'Rubik-ExtraBold',sans-serif;
      font-weight: 800;
      font-size: 18px;
      line-height: 130%;
      color: #131313;
    }
  }

  &__detail {
    padding-left: 45px;
    font-family: 'Rubik-Regular',sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #77797A;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__btn-next {
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 52px;
    color: #FFFFFF;
    background: #009757;
    border-radius: 76px;
    width: 171px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 55px;
    right: 30px;
  }

  &__email-send {
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    width: 480px;
    position: absolute;
    bottom: 100px;
    right: 70px;

    p {
      margin-bottom: 20px;
    }
  }

  &__email-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }

  &__email-btn-green, &__email-btn-red {
    background: #009757;
    border-radius: 76px;
    width: 120px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    line-height: 52px;
    color: #FFFFFF;
    font-family: 'Rubik-Bold', sans-serif;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }

  &__email-btn-green {
    background-color: #009757;
  }

  &__email-btn-red {
    background-color: #E6215A;
  }
}
