.header {
  width: 100%;
  height: 238px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-block {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  & > img {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.text {
  max-width: 950px;
  text-align: center;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #FFFFFF;
  white-space: pre-line;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 30px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 100px 0 0 100px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  span {
    font-family: 'Rubik-ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 14px;
    color: #BC213E;
    margin-left: 15px;
  }
}