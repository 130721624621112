.wrapper {
  width: 100%;
  height: 100vh;
  padding: 30px;
  background-color: #DDDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  width: 100%;
  max-width: 1920px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}