.wrapper {
  width: 600px;
  height: 495px;
  border-radius: 10px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.text-wrapper {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 38px;
  line-height: 56px;
  text-align: center;
  color: white;
  padding: 0 60px;
  white-space: pre-line;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 458px;
  height: 102px;
  border: 3.5px solid #FFFFFF;
  border-radius: 88px;
  font-size: 28px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  color: white;
  cursor: pointer;
}