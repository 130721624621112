.modal-wrapper {
  width: 818px;
  padding: 0 10px;
}

.content {
  margin-bottom: 50px;

  &__title {
    font-family: 'Rubik-ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    white-space: pre-line;
  }

  &__place {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    color: #77797A;
    margin-bottom: 20px;
  }

  &__description {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  &__amount {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    color: #77797A;

    b {
      color: #000;
      font-family: 'Rubik-Bold', sans-serif;
      font-weight: 700;
    }
  }
}

.images {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.btn {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: transparent;
  border: none;

  span {
    font-family: 'Rubik-ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 0;
    text-transform: uppercase;
    color: #009757;
    transform: translateY(2px);
  }
}