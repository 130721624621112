.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  flex: 1 1 auto;

  img {
    width: 100%;
    height: 930px;
    object-fit: cover;
    object-position: center;
  }
}

.content-wrapper {
  max-width: 732px;
  position: absolute;
  top: 175px;
  left: 193px;

  p {
    color: #FFFFFF;
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
  }
}

.first-text {
  margin-bottom: 20px;
}

.second-text {
  margin-bottom: 65px;
}

.btn {
  background-color: transparent;
  width: 458px;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3.5px solid #FFFFFF;
  border-radius: 88px;
  color: #FFFFFF;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 14px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #E6215A;
    border: 3.5px solid #E6215A;
  }
}