.tracker {
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  min-height: 100vh;

  &__logo {
    position: absolute;
    top: 35px;
    left: 90px;
  }

  &__back {
    top: 15px;
    right: 0;
  }

  &__info {
    width: 597px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 80px;
    background: #F7F9FB;

    &-titl {
      max-width: 374px;
      font-family: 'Rubik-ExtraBold', sans-serif;
      font-weight: 800;
      font-size: 58px;
      line-height: 64px;
      color: #3C3C3B;
      transform: translateY(-50px);
    }
  }
}

.info {
  display: flex;
  position: relative;

  &__left {
    width: 30%;
    padding: 90px 20px 50px 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  &__right {
    width: 70%;
    padding: 60px 90px 100px 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    width: 990px;

    p {
      margin-bottom: 20px;
    }
  }

  &__bold-text {
    font-family: 'Rubik-ExtraBold', sans-serif;
    font-weight: 800;
  }
}