.wrapper {
  max-width: 356px;
  user-select: none;
  cursor: pointer;
  justify-self: center;

  img {
    margin-bottom: 20px;
    border-radius: 10px;
  }
}

.name {
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 130%;
  margin-bottom: 10px;
  white-space: pre-line;
}

.location {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #77797A;
}
