.content {
  padding: 50px 365px 0;
}

.title {
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 40px;
  line-height: 130%;
  text-align: center;
  color: #EF7D00;
  margin-bottom: 60px;
}

.flex-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 110px;

  img {
    border-radius: 10px;
  }
}

.text {
  max-width: 628px;
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 40px;
  line-height: 130%;
  margin-right: 162px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.btn {
  border-radius: 10px;
  width: 326px;
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  border: none;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 37px;

  &-red {
    background: #E62259;
  }

  &-green {
    background: #009757;
  }
}

.modal-container {
  width: 494px;
  text-align: center;
  padding-top: 35px;
}

.modal-text {
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  margin: 55px auto 30px;
  max-width: 390px;
}

.modal-btn {
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  color: #EF7D00;
  margin-bottom: 50px;
}