.answer-item {
  margin: 25px 0;
  position: relative;
  display: flex;
  align-items: flex-start;

  img {
    position: absolute;
    z-index: 1;
  }
}

.answer-text {
  padding-left: 74px;
  transform: translateY(4px);

  input {
    display: none;
  }

  label {
    font-family: 'Rubik-Light', sans-serif;
    font-size: 26px;
    font-weight: 300;
    line-height: 32px;
  }
}

.comment {
  margin: 25px 0 25px 120px;
  font-style: italic;
  font-family: 'Rubik-Medium', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
}
