.content {

}

.title {
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 40px;
  line-height: 130%;
  text-align: center;
  padding: 20px 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  column-gap: 30px;
  row-gap: 20px;
  width: fit-content;
  margin: 0 auto;
}

.grid-item {
  display: flex;
  align-items: center;
  gap: 30px;
}

.big-img {
  border-radius: 10px;
}