.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 30px;
}

.loading {
	text-align: center;
	font-size: 4rem;
	padding: 8rem 0;
	position: absolute;
    width: 100%;
    top: 0;
}

.map-container {
	height: 80vh;
	width: 100vw;
	position: relative;
	margin: 1rem 0;
}

.map {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
 
	&-container {
	  position: relative;
	  width: 100%;
	  padding-top: 42.12%; // Пропорция высоты карты относительно ширины
	}
 
	path {
	  transition: .3s;
	  stroke: white;
 
	  &:hover {
		 stroke: rgb(248, 184, 52);
		 stroke-width: 2px;
	  }
	}
 }