.decor-wrapper {
  position: relative;
}
.decor-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
}

.more {
  display: flex;
  position: relative;
  height: 100vh;
}
.more__logo {
  position: absolute;
  top: 35px;
  left: 90px;
}
.more__preview {
  width: 50%;
}
.more__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}
.more__info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.more__tracker {
  background: #009757;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 90px;
}
.more__tracker-title {
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 66px;
  line-height: 64px;
  color: #fff;
}
.more__tracker-text {
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 400;
  font-size: 34px;
  line-height: 38px;
  color: #fff;
}
.more__view {
  background: #005DAC url("../../assets/images/Tracker/more-bg.png") no-repeat center;
  background-size: cover;
  height: 370px;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 90px;
}
.more__view-text {
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 39px;
  line-height: 69px;
  color: #fff;
}
.more__view-btn {
  width: 158px;
  height: 85px;
  background: #E6215A;
  border-radius: 100vw;
  border: none;
  font-family: 'Rubik-ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 33px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back {
  position: absolute;
  top: 65px;
  right: 25px;
  background: #009757;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 60px;
  width: 160px;
  border-radius: 30px 0 0 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  z-index: 3;
  cursor: pointer;
}
