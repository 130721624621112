.content {
  padding: 60px 150px;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-block {

}

.title {
max-width: 900px;
  font-size: 32px;
  line-height: 130%;
  font-family: 'Rubik-Medium', sans-serif;
  font-weight: 500;
  color: #00A555;
  margin-bottom: 30px;
}

.text {
  max-width: 712px;
  font-size: 20px;
  line-height: 130%;
  font-family: 'Rubik-Medium', sans-serif;
  font-weight: 500;
  margin-bottom: 60px;
}

.warning {
  font-size: 28px;
  line-height: 130%;
  font-family: 'Rubik-Medium', sans-serif;
  font-weight: 500;
  margin-bottom: 130px;
}

.btn {
  border: 3.5px solid #00A555;
  border-radius: 88px;
  width: 458px;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27.8281px;
  line-height: 14px;
  font-family: 'Rubik-Bold', sans-serif;
  font-weight: 700;
  color: #00A555;
  cursor: pointer;

  &:hover {
    background-color: #00A555;
    color: #FFFFFF;
  }
}

.right-block {

}

