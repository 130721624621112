.block {
  height: 143px;
  background: #EF7D00;
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 77px;
  }

  a {
    font-family: 'Rubik-ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;
    color: #FFFFFF;
    max-width: 550px;
  }

  &-left {
    justify-content: flex-end;
    padding-right: 60px;
    border-radius: 0 70px 70px 0;
    text-align: right;
  }

  &-right {
    justify-content: flex-start;
    padding-left: 60px;
    border-radius: 70px 0 0 70px;
  }
}

.line {
  position: absolute;
  top: 50%;
  right: -120px;
  z-index: 0;

  &-left {
    transform: scaleX(-1);
  }
}